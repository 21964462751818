import React, { useLayoutEffect, useRef } from 'react'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import styles from './BenefitSection.module.scss'
import { CanvasContainer } from './CanvasContainer'
import useResize from 'helpers/useResize'

gsap.registerPlugin(ScrollTrigger)

export default function BenefitSection() {
  const benefitRef1 = useRef(null)
  const { width } = useResize()
  const sectionRef = useRef(null)

  useLayoutEffect(() => {
    if (width > 576) {
      const isTablet = width <= 1024
      new ScrollTrigger({})
      const tl = gsap.timeline()
      tl.from('.benefitWrapper1', {
        opacity: 0,
        duration: 2,
        scrollTrigger: {
          trigger: '.benefit',
          start: 'top center',
          end: 'top top',
          scrub: 1,
        },
      })
        .to('.benefitWrapper1', {
          opacity: 1,
          duration: 2,
          scrollTrigger: {
            trigger: '.benefit',
            start: 'top center',
            end: 'top top',
            scrub: 1,
          },
        })

        .from('.benefitWrapper4', {
          opacity: 0,
          duration: 2,
          scrollTrigger: {
            trigger: isTablet ? '.benefitWrapper3' : '.benefit',
            start: 'top center',
            end: 'top top',
            scrub: 1,
          },
        })
        .to('.benefitWrapper4', {
          opacity: 1,
          duration: 2,
          scrollTrigger: {
            trigger: isTablet ? '.benefitWrapper3' : '.benefit',
            start: 'top center',
            end: 'top top',
            scrub: 1,
          },
        })

        .from('.benefitWrapper2', {
          opacity: 0,
          duration: 2,
          scrollTrigger: {
            trigger: '.benefitWrapper1',
            start: 'top center',
            end: 'top top',
            scrub: 1,
          },
        })
        .to('.benefitWrapper2', {
          opacity: 1,
          duration: 2,
          scrollTrigger: {
            trigger: '.benefitWrapper1',
            start: 'top center',
            end: 'top top',
            scrub: 1,
          },
        })
        .from('.benefitWrapper3', {
          opacity: 0,
          duration: 2,
          scrollTrigger: {
            trigger: isTablet
              ? '.benefitWrapper2'
              : '.benefitWrapper1',
            start: 'top center',
            end: 'top top',
            scrub: 1,
          },
        })
        .to('.benefitWrapper3', {
          opacity: 1,
          duration: 2,
          scrollTrigger: {
            trigger: isTablet
              ? '.benefitWrapper2'
              : '.benefitWrapper1',
            start: 'top center',
            end: 'top top',
            scrub: 1,
          },
        })
        .from('.benefitWrapper5', {
          opacity: 0,
          duration: 2,
          scrollTrigger: {
            trigger: isTablet
              ? '.benefitWrapper3'
              : '.benefitWrapper1',
            start: 'top center',
            end: 'top top',
            scrub: 1,
          },
        })
        .to('.benefitWrapper5', {
          opacity: 1,
          duration: 2,
          scrollTrigger: {
            trigger: isTablet
              ? '.benefitWrapper3'
              : '.benefitWrapper1',
            start: 'top center',
            end: 'top top',
            scrub: 1,
          },
        })
    }
  }, [width])

  return (
    // @ts-ignore
    <section
      className={`${styles.BenefitSectionContainer} benefit`}
      // @ts-ignore
      ref={sectionRef}
      id="possibilities"
    >
      <div className={`container ${styles.BenefitSectionScroll}`}>
        <div className={styles.BenefitBlock}>
          <div
            ref={benefitRef1}
            className={`${styles.BenefitWrapper} ${styles.BenefitWrapper1} benefitWrapper1`}
          >
            <div className={styles.BenefitTitle}>
              <span>Удобная </span>
              продажа и покупка
            </div>
            <span className={styles.BenefitDescription}>
              Покупайте и продавайте в <br />
              несколько простых шагов <br /> в удобном приложении
            </span>
          </div>
          <div
            className={`${styles.BenefitWrapper} ${styles.BenefitWrapper2} benefitWrapper2`}
          >
            <div className={styles.BenefitTitle}>
              <span>Простое </span>
              наследство
            </div>
            <span className={styles.BenefitDescription}>
              Настройте автоматическое наследство <br /> своих Urals.
              Быстро, удобно, без <br /> нотариусов и лишних
              документов.
            </span>
          </div>
          <div
            className={`${styles.BenefitWrapper} ${styles.BenefitWrapper3} benefitWrapper3`}
          >
            <div className={styles.BenefitTitle}>
              Инвестиции и<span> доход</span>
            </div>
            <span className={styles.BenefitDescription}>
              Пусть ваши деньги приносят доход. <br />
              Инвестируйте средства в paycashswap <br /> и получайте
              ежедневный доход.
            </span>
          </div>
        </div>
        <div
          className={`${styles.BenefitBlock} ${styles.BenefitRightBlock}`}
        >
          <div
            className={`${styles.BenefitWrapper} ${styles.BenefitWrapper4} benefitWrapper4`}
          >
            <div className={styles.BenefitTitle}>
              <span>Безопасное </span>
              хранение активов
            </div>
            <span className={styles.BenefitDescription}>
              Используйте криптокошельки <br /> с поддержкой сети EOS,
              чтобы <br />
              ваша криптонефть была в <br /> безопасности и под
              контролем.
            </span>
          </div>
          <div
            className={`${styles.BenefitWrapper} ${styles.BenefitWrapper5} benefitWrapper5`}
          >
            <div className={styles.BenefitTitle}>
              <span>Моментальные </span>
              переводы
            </div>
            <span className={styles.BenefitDescription}>
              Мгновенный переводы криптонефти <br /> без скрытых
              комиссий и задержек. <br /> Отправляйте пользователям
              любые <br /> обьемы URALS.
            </span>
          </div>
        </div>
      </div>
      <div
        className={styles.BenefitCanvas}
        style={{
          display: 'block',
          zIndex: 10,
          // height: 'calc(100vh - 147px)',
          width: '100%',
          position: 'absolute',
          top: 0,
          minHeight: 914,
          maxHeight: 914,
          overflow: 'hidden',
        }}
      >
        <CanvasContainer />
      </div>
    </section>
  )
}
