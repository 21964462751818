import React from 'react'

import styles from './index.module.scss'

export default function Navigation({
  className,
}: {
  className?: string
}) {
  return (
    <nav className={`${className && className}`}>
      <ul className={styles.NavigationList}>
        <li>
          <a className={styles.ListLink} href="#possibilities">
            Возможности
          </a>
        </li>
        <li>
          <a className={styles.ListLink} href="#about">
            О токене
          </a>
        </li>
        <li>
          <a className={styles.ListLink} href="#investments">
            Инвестиции
          </a>
        </li>
        <li>
          <a className={styles.ListLink} href="#fag">
            FAQ
          </a>
        </li>
      </ul>
    </nav>
  )
}
