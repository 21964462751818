import React from 'react'

import styles from './mobileNav.module.scss'

export default function NavigationMobile({
  className,
  setCloseMenu,
}: {
  className?: string
  setCloseMenu: () => void
}) {
  return (
    <nav className={`${styles.Navigation} ${className && className}`}>
      <ul className={styles.NavigationList}>
        <li>
          <a
            className={styles.ListLink}
            href="#possibilities"
            onClick={setCloseMenu}
          >
            Возможности
          </a>
        </li>
        <li>
          <a
            className={styles.ListLink}
            href="#about"
            onClick={setCloseMenu}
          >
            О токене
          </a>
        </li>
        <li>
          <a
            className={styles.ListLink}
            href="#investments"
            onClick={setCloseMenu}
          >
            Инвестиции
          </a>
        </li>
        <li>
          <a
            className={styles.ListLink}
            href="#fag"
            onClick={setCloseMenu}
          >
            FAQ
          </a>
        </li>
      </ul>
    </nav>
  )
}
