import React, { useState } from 'react'

import styles from './index.module.scss'
import { ReactComponent as ChevronIcon } from '../../assets/images/chevron.svg'

interface FAQItemProps {
  title: string
  text: React.ReactNode
  className?: string
}
export const FAQItem = ({ title, text, className }: FAQItemProps) => {
  const [isActive, setActive] = useState(false)

  const handleClick = () => {
    setActive(!isActive)
  }

  return (
    <div
      className={`${styles.faqContainer} ${className && className}`}
    >
      <button
        onClick={handleClick}
        className={`${styles.faqTitle} ${
          isActive && styles.faqTitleActive
        }`}
      >
        {title}

        <div className={styles.fagArrow}>
          <ChevronIcon />
        </div>
      </button>
      <div
        className={`${styles.faqContent} ${
          isActive && styles.faqContentActive
        }`}
      >
        {text}
      </div>
    </div>
  )
}
