import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useLayoutEffect,
} from 'react'
import gsap from 'gsap'

import styles from './index.module.scss'
import Navigation from '../Navigation'
import logo from '../../assets/images/logo.svg'
import { Button } from '../Button'
import NavigationMobile from './NavigationMobile'

export default function Header() {
  const el = useRef()
  const tl = useRef()
  const ref = useRef(null)
  const [menuIsOpen, setIsOpenMenu] = useState(false)

  useLayoutEffect(() => {
    gsap.context(() => {
      // @ts-ignore
      tl.current = gsap
        .timeline({ ease: 'none' })
        .fromTo(
          '.header',
          { y: -20, opacity: 0 },
          { duration: 1, y: 0, opacity: 1 },
        )
    }, el)
  }, [])

  useEffect(() => {
    // Function to handle window resize event
    const handleResize = () => {
      if (menuIsOpen && window.innerWidth > 846) {
        document.body.classList.remove('menu-open')
        setIsOpenMenu(false)
      }
    }
    // Add event listener for window resize
    window.addEventListener('resize', handleResize)

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [menuIsOpen])

  const handleMenuIconClick = useCallback(() => {
    if (!menuIsOpen) {
      document.body.classList.add('menu-open')
    } else {
      document.body.classList.remove('menu-open')
    }
    setIsOpenMenu(!menuIsOpen)
  }, [menuIsOpen, setIsOpenMenu])

  const handleBtnCLick = () => {
    window.location.href = '#buy'
  }

  const handleCloseMenu = () => {
    setIsOpenMenu(false)
    handleMenuIconClick()
  }

  return (
    <header
      className={`${styles.HeaderContainer} ${
        menuIsOpen ? styles.HeaderContainerMobile : ''
      }`}
      // @ts-ignore
      ref={el}
    >
      <div className="container header">
        <div className={`${styles.Header}`}>
          <a href="/" className={styles.HeaderLogoLink}>
            <img src={logo} alt="Logo" />
          </a>

          <Navigation className={styles.HeaderNavDesktop} />

          <Button
            typeView="blue"
            className={styles.HeaderBtn}
            onClick={handleBtnCLick}
          >
            Купить криптонефть
          </Button>

          <div
            className={`${styles.HeaderMobileNav} ${
              menuIsOpen && styles.HeaderMobileNavActive
            }`}
          >
            <div
              ref={ref}
              onClick={handleMenuIconClick}
              className={styles.mobileMenuIcon}
            />
            <div
              className={`${styles.MenuNavIcon} ${
                menuIsOpen && styles.MenuNavIconOpen
              }`}
            />

            <div className={`container ${styles.MobileNav}`}>
              <NavigationMobile
                className={styles.MobileNavList}
                setCloseMenu={handleCloseMenu}
              />

              <Button
                typeView="blue"
                className={styles.HeaderMobileBtn}
                onClick={() => {
                  handleBtnCLick()
                  handleMenuIconClick()
                }}
              >
                Купить криптонефть
              </Button>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
