import React, { useLayoutEffect, useRef } from 'react'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import commonStyles from './../index.module.scss'
import styles from './MainSection.module.scss'
import { Button } from 'components/Button'
import { ReactComponent as ArrowIcon } from 'assets/images/arrow.svg'
import useResize from 'helpers/useResize'

gsap.registerPlugin(ScrollTrigger)
export default function MainSection() {
  const el = useRef()
  const { width, height } = useResize()

  useLayoutEffect(() => {
    new ScrollTrigger({})
    const tl = gsap.timeline()
    let ctx: any
    if (width > 768 && height <= 800) {
      tl.fromTo(
        'h1',
        { y: -50, opacity: 1 },
        { duration: 1, y: 110, opacity: 1 },
        0,
      )
        .fromTo(
          '.mainSectionInfoWrapperLeft',
          { y: -50 },
          { duration: 1, y: 0 },
          0,
        )
        .fromTo(
          '.mainSectionInfoWrapperRight',
          { y: 50 },
          { duration: 1, y: 0 },
          0,
        )
        .fromTo(
          '.mainSectionBtn',
          { y: -20 },
          { duration: 1, y: 160 },
          0,
        )
    } else if (width > 768 && height > 800) {
      tl.fromTo(
        'h1',
        { y: -50, opacity: 1 },
        { duration: 1, y: '25vh', opacity: 1 },
        0,
      )
        .fromTo(
          '.mainSectionInfoWrapperLeft',
          { y: -50 },
          { duration: 1, y: 0 },
          0,
        )
        .fromTo(
          '.mainSectionInfoWrapperRight',
          { y: 50 },
          { duration: 1, y: 0 },
          0,
        )
        .fromTo(
          '.mainSectionBtn',
          { y: -20, opacity: 0 },
          { duration: 1, y: '30vh', opacity: 1 },
          0,
        )
    } else {
      tl.fromTo('h1', { opacity: 0 }, { duration: 2, opacity: 1 }, 0)
        .fromTo(
          '.mainSectionInfoWrapperLeft',
          { y: -50 },
          { duration: 1, y: 0 },
          0,
        )
        .fromTo(
          '.mainSectionInfoWrapperRight',
          { y: -50 },
          { duration: 1, y: 0 },
          0,
        )
        .fromTo(
          '.mainSectionBtn',
          { opacity: 0, y: 0 },
          { duration: 1, opacity: 1, y: 0 },
          0,
        )
    }

    return () => {
      ctx && ctx.kill()
    }
  }, [width])

  const handleBtnMoreCLick = () => {
    window.location.href = '#about'
  }

  return (
    <section
      className={`${styles.MainSectionContainer} mainBarrels`}
      // @ts-ignore
      ref={el}
    >
      <div className={`container ${styles.MainSectionPosition}`}>
        <div className={styles.MainSectionInfoContainer}>
          <div
            className={`${styles.MainSectionInfoWrapper} ${styles.MainSectionInfoWrapperLeft} mainSectionInfoWrapperLeft`}
          >
            <span className={styles.MainSectionInfoTitle}>
              Выпущено
            </span>
            <span className={styles.MainSectionInfoAmount}>1834</span>
            <div className={styles.MainSectionInfoType}>
              Барреля <span>bbls</span>
            </div>
          </div>

          <div
            className={`${styles.MainSectionInfoWrapper} ${styles.MainSectionInfoWrapperRight} mainSectionInfoWrapperRight`}
          >
            <span className={styles.MainSectionInfoTitle}>
              Цена барреля
            </span>
            <span className={styles.MainSectionInfoAmount}>
              73.80
            </span>
            <div className={styles.MainSectionInfoType}>
              Долларов <span>$</span>
            </div>
          </div>
        </div>

        <h1
          className={`${commonStyles.Title} ${styles.MainSectionTitle}`}
        >
          Криптовалюта обеспеченная{' '}
          <span className={commonStyles.TitleUnderline}>нефтью</span>
        </h1>

        <Button
          typeView="blue"
          rightIcon={<ArrowIcon />}
          className={`${styles.MainSectionBtn} mainSectionBtn`}
          onClick={handleBtnMoreCLick}
        >
          Подробнее
        </Button>

        <img
          className={styles.MainSectionCoin}
          src={require('../../../assets/images/urals_main.png')}
          alt="Urals coin"
        />

        <img
          className={styles.MainSectionBarrels}
          src={require('../../../assets/images/main_section_bg.png')}
          alt="Barrels"
        />
      </div>
    </section>
  )
}
