import React, { useLayoutEffect } from 'react'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import globalStyles from './../index.module.scss'
import styles from './CryptoOilSection.module.scss'
import { ReactComponent as EqualsIcon } from 'assets/images/equals.svg'

gsap.registerPlugin(ScrollTrigger)
export default function CryptoOilSection() {
  useLayoutEffect(() => {
    new ScrollTrigger({})
    const tl = gsap.timeline()
    tl.from('#about', {
      opacity: 0,
      duration: 2,
      scrollTrigger: {
        trigger: '.benefitWrapper5',
        start: 'top center',
        end: 'top top',
        scrub: 1,
      },
    }).to('#about', {
      opacity: 1,
      duration: 2,
      scrollTrigger: {
        trigger: '.benefitWrapper5',
        start: 'top center',
        end: 'top top',
        scrub: 1,
      },
    })
  }, [])

  return (
    <section className={'container'} id="about">
      <div className={styles.CryptoOilContainer}>
        <div className={styles.CryptoOilImages}>
          <img
            src={require('../../../assets/images/oil.png')}
            alt="Barrels"
          />
          <EqualsIcon />
          <img
            src={require('../../../assets/images/urals.png')}
            alt="Urals coin"
          />
        </div>
        <h2 className={globalStyles.Title}>
          Криптонефть к сырой нефти <span>навсегда</span> 1 к 1
        </h2>

        <p>
          Это достигается за счет полного обеспечения криптонефти
          находящейся на хранение у надежной компании АК Транснефть
          внутри ее нефтехранилищ и действующих трубопроводов
        </p>
      </div>
    </section>
  )
}
