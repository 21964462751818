import React, { useLayoutEffect, useRef } from 'react'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import globalStyles from './../index.module.scss'
import styles from './AdvantagesSection.module.scss'
import { Button } from 'components/Button'
import { ReactComponent as PayCashIcon } from 'assets/images/payCash.svg'
import { ReactComponent as MalinkaIcon } from 'assets/images/malinka.svg'
import { ReactComponent as KalynaIcon } from 'assets/images/kalyna.svg'
import useResize from 'helpers/useResize'

gsap.registerPlugin(ScrollTrigger)

export default function AdvantagesSection() {
  const el = useRef()
  const { width } = useResize()

  useLayoutEffect(() => {
    new ScrollTrigger({})
    const tl = gsap.timeline()

    tl.fromTo(
      // @ts-ignore
      el?.current,
      {
        opacity: 0,
        y: 50,
      },
      {
        opacity: 1,
        duration: 2,
        y: 0,
        scrollTrigger: {
          trigger: el?.current,
          toggleActions: 'restart none none none',
          start: 'top 90%',
          end: 'bottom 95%',
          scrub: true,
        },
      },
      0,
    )
  }, [width])
  const handlePayCash = () => {
    window.location.replace('https://paycash.app')
  }

  const handleMalinka = () => {
    window.location.replace('https://malinka.life')
  }

  const handleKalina = () => {
    window.location.replace('https://kalyna.online')
  }

  return (
    <section
      // @ts-ignore
      ref={el}
      className={`container ${styles.AdvantagesSectionWrapper}`}
    >
      <div className={styles.AdvantagesSectionContainer}>
        <h2 className={globalStyles.Title}>
          Воспользуйтесь <br /> всеми <span>преимуществами</span>
        </h2>

        <p>
          Скачайте одно из приложений, чтобы воспользоваться всеми
          преимуществами токена Urals
        </p>

        <div className={styles.AdvantagesSectionLinkList}>
          <Button
            typeView="outlined"
            leftIcon={<PayCashIcon />}
            onClick={handlePayCash}
          >
            PayCash
          </Button>
          <Button
            typeView="outlined"
            leftIcon={<MalinkaIcon />}
            onClick={handleMalinka}
          >
            Malinka
          </Button>
          <Button
            typeView="outlined"
            leftIcon={<KalynaIcon />}
            onClick={handleKalina}
          >
            Kalyna
          </Button>
        </div>
      </div>
    </section>
  )
}
