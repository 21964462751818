/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 urals.gltf --transform
Files: urals.gltf [172.54MB] > /Users/nika/Downloads/urals2/urals2/urals-transformed.glb [5.68MB] (97%)
*/

import React, { useLayoutEffect, useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { useThree } from '@react-three/fiber'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

// Enable ScrollTrigger
gsap.registerPlugin(ScrollTrigger)

export function Urals(props) {
  const { nodes, materials } = useGLTF('/urals-transformed.glb')
  const ref = useRef()
  const { scene } = useThree()
  const tl = gsap.timeline()

  useLayoutEffect(() => {
    new ScrollTrigger({})
    // component About.tsx
    tl.to(scene.position, {
      y: '-6',
      scrollTrigger: {
        trigger: '.benefit',
        start: 'top center',
        end: 'top top',
        scrub: 1,
        scale: 5,
      },
    }).to(scene.rotation, {
      y: -9,
      scrollTrigger: {
        trigger: '.benefit',
        start: 'top center',
        end: 'top top',
        scrub: true,
      },
    })
  }, [])

  return (
    <group
      {...props}
      dispose={null}
      position={[0, 6, 0]}
      rotation-z={-0.6}
      rotation-y={-0.44}
      rotation-x={2}
      ref={ref}
    >
      <mesh
        geometry={nodes.Retopo_Curve020.geometry}
        material={materials.PaletteMaterial002}
        scale={[45, 14.795, 45]}
        // percentage={props.percentage}
      />
    </group>
  )
}

useGLTF.preload('/urals-transformed.glb')
