import React from 'react'

import globalStyles from './../index.module.scss'
import styles from './FAQSection.module.scss'
import { FAQItem } from 'components/FAQItem'
export default function FAQSection() {
  return (
    <section
      className={`container ${styles.FAQSectionContainer}`}
      id="fag"
    >
      <h2 className={globalStyles.Title}>Часто задаваемые вопросы</h2>

      <div>
        <FAQItem
          title="Чем обеспечен токен?"
          text="Токен обеспечен сырой нефтью находящейся на хранение у надежной  компании АК Транснефть внутри ее нефтехранилищ и действующих трубопроводов. Каждой токен URALS равен 1 баррелю нефти Urals"
        />
        <FAQItem
          title="От чего зависит цена токена?"
          text="Цена токена привязана к цене барреля нефти URALS и зависит от множества факторов. При большом спросе цена токена будет расти, при большом предложении на рынке цена токена будет падать."
        />
        <FAQItem
          title="Как купить URALS?"
          text="Для покупки токена скачайте одно из рекомендованных приложений. Создайте новый аккаунт или воспользуйтесь существующим, пополните баланс аккаунта через раздел “Пополнение и вывод”. Далее обменяйте токен на URALS через раздел “Обмен”"
        />
        <FAQItem
          title="В каких приложениях доступен токен?"
          text="Полноценная поддержка токена со всеми возможностями есть в следующих приложениях - PayCash, Kalyna, Malinka."
          className={styles.FAQItem}
        />
      </div>
    </section>
  )
}
