import React, { useLayoutEffect } from 'react'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import globalStyles from './../index.module.scss'
import styles from './InvestmentSection.module.scss'
import { Button } from 'components/Button'
import { ReactComponent as ArrowIcon } from 'assets/images/arrow.svg'

gsap.registerPlugin(ScrollTrigger)
export default function InvestmentSection() {
  useLayoutEffect(() => {
    new ScrollTrigger({})
    const tl = gsap.timeline()
    tl.fromTo(
      'h2',
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 2,
        scrollTrigger: {
          trigger: 'h2',
          toggleActions: 'restart none none none',
          start: 'top 90%',
          end: 'bottom 95%',
          scrub: true,
        },
      },
      0,
    )
    tl.fromTo(
      '.investmentSectionDescLeft',
      {
        opacity: 0.4,
        y: 100,
      },
      {
        opacity: 1,
        duration: 4,
        y: 0,
        scrollTrigger: {
          trigger: '.investmentSectionDescLeft',
          toggleActions: 'restart none none none',
          start: 'top 90%',
          end: 'bottom 95%',
          scrub: true,
        },
      },
      0,
    )
    tl.fromTo(
      '.investmentSectionDescRight',
      {
        opacity: 0.4,
        y: 100,
      },
      {
        opacity: 1,
        y: 0,
        duration: 1,
        scrollTrigger: {
          trigger: '.investmentSectionDescRight',
          toggleActions: 'restart none none none',
          start: 'top 90%',
          end: 'bottom 95%',
          scrub: true,
        },
      },
      0,
    )
  }, [])

  const handleClick = () => {
    window.location.replace('https://paycashswap.com/pool/LQLJ')
  }

  return (
    <section
      className={`container ${styles.InvestmentSectionContainer}`}
      id="investments"
    >
      <h2 className={globalStyles.Title}>
        <span>Инвестируйте </span>в криптонефть
      </h2>

      <div className={styles.InvestmentSectionDescWrrapper}>
        <div
          className={`${styles.InvestmentSectionDescLeft} investmentSectionDescLeft`}
        >
          <div>
            <h3>
              Пассивные <span>инвестиции</span>
            </h3>
            <p>
              Покупайте криптонефть URALS <br />и зарабатывайте на
              росте ее цены
            </p>
          </div>
          <img
            src={require('../../../assets/images/passive_investment.png')}
            alt="Barrels"
          />
        </div>
        <div
          className={`${styles.InvestmentSectionDescRight} investmentSectionDescRight`}
        >
          <div>
            <h3>
              <span>Дополнительный</span> доход
            </h3>
            <p>
              Добавляйте токен urals в пулы ликвидности и получайте{' '}
              <br />
              дополнительный доход в виде бонус-токена малинки
            </p>
            <Button
              typeView="purple"
              size="big"
              rightIcon={<ArrowIcon />}
              className={styles.InvestmentSectionBtn}
              onClick={handleClick}
            >
              Перейти
            </Button>
          </div>
          <img
            src={require('../../../assets/images/additional_income.png')}
            alt="Barrels"
          />
        </div>
      </div>
    </section>
  )
}
