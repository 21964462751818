import React, { useState } from 'react'

import globalStyles from './../index.module.scss'
import styles from './HowToBuySection.module.scss'

type SliderType = '1' | '2' | '3' | '4'
export default function HowToBuySection() {
  const [slider, setSlider] = useState<SliderType>('1')

  return (
    <section
      id="buy"
      className={`container ${styles.HowToBuyContainerWrapper}`}
    >
      <div className={styles.HowToBuyContainer}>
        <div
          className={`${styles.HowToBuyProgressLine}`}
          style={{ width: `${25 * +slider}%` }}
        />
        <div className={styles.HowToBuyDescriptionBlock}>
          <h2 className={globalStyles.Title}>
            Как купить <span>криптонефть</span>
          </h2>
          <ol className={styles.HowToBuyList}>
            <li
              className={
                slider === '1' ? styles.HowToBuyListActive : ''
              }
            >
              <span
                className={`${styles.HowToBuyTitle}`}
                onClick={() => setSlider('1')}
              >
                Скачайте приложение
              </span>
              <span className={`${styles.HowToBuyDescription}`}>
                Скачайте одно из рекомендованых приложений - PayCash,
                Kalyna или Malinka
              </span>
            </li>
            <li
              className={
                slider === '2' ? styles.HowToBuyListActive : ''
              }
            >
              <span
                className={`${styles.HowToBuyTitle}`}
                onClick={() => setSlider('2')}
              >
                Создайте аккаунт
              </span>
              <span className={`${styles.HowToBuyDescription}`}>
                Создайте EOS аккаунт в приложении, или используйте уже
                существующий
              </span>
            </li>
            <li
              className={
                slider === '3' ? styles.HowToBuyListActive : ''
              }
            >
              <span
                className={`${styles.HowToBuyTitle}`}
                onClick={() => setSlider('3')}
              >
                Пополните баланс
              </span>
              <span className={`${styles.HowToBuyDescription}`}>
                Перейдите в раздел “Пополнение и вывод”, выберите
                вкладку “Пополнение”, введите сумму на которую хотите
                купить токен. Далее необходимо оплатить заявку.
              </span>
            </li>
            <li
              className={`${styles.HowToBuyLi4} ${
                slider === '4' ? styles.HowToBuyListActive : ''
              }`}
            >
              <span
                className={`${styles.HowToBuyTitle}`}
                onClick={() => setSlider('4')}
              >
                Обменяйте токен
              </span>
              <span className={`${styles.HowToBuyDescription}`}>
                Перейдите в раздел “Обмен”, выберите токен который
                только что приобрели и обменяйте его на токен URALS
              </span>
            </li>
          </ol>
        </div>
        <div>
          <img
            src={require('../../../assets/images/how_to_buy_1.png')}
            alt="Скриншот с приложением Kalyna в AppStore"
            className={`${styles.HowToBuyImg} ${
              slider === '1' ? styles.HowToBuyImgActive : ''
            }`}
          />
          <img
            src={require('../../../assets/images/how_to_buy_2.png')}
            alt="Скриншот создания кошелька в приложением Kalyna"
            className={`${styles.HowToBuyImg} ${
              slider === '2' ? styles.HowToBuyImgActive : ''
            }`}
          />
          <img
            src={require('../../../assets/images/how_to_buy_3.png')}
            alt="Скриншот пополнения и вывода в приложением Kalyna"
            className={`${styles.HowToBuyImg} ${
              slider === '3' ? styles.HowToBuyImgActive : ''
            }`}
          />
          <img
            src={require('../../../assets/images/how_to_buy_4.png')}
            alt="Скриншот обмена в приложением Kalyna"
            className={`${styles.HowToBuyImg} ${
              slider === '4' ? styles.HowToBuyImgActive : ''
            }`}
          />
        </div>
      </div>
    </section>
  )
}
