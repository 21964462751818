import React from 'react'
import styles from './index.module.scss'

type ButtonBaseProps = {
  leftIcon?: React.ReactNode
  rightIcon?: React.ReactNode
  typeView: 'purple' | 'blue' | 'primary' | 'outlined'
  size?: 'default' | 'big'
  className?: string
}

type ButtonProps = ButtonBaseProps &
  Omit<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    keyof ButtonBaseProps
  >

export const Button = ({
  leftIcon,
  rightIcon,
  children,
  typeView,
  className,
  size = 'default',
  ...props
}: ButtonProps) => {
  return (
    <button
      className={`
        ${styles.button} 
        ${styles[typeView]}
        ${styles[`size-${size}`]}
        ${className ? className : ''}
      `}
      {...props}
    >
      {leftIcon && leftIcon}
      {children}
      {rightIcon && rightIcon}
    </button>
  )
}
