import React from 'react'

import styles from './index.module.scss'
import MainSection from './components/MainSection'
import BenefitSection from './components/BenefitSection'
import CryptoOilSection from './components/CryptoOilSection'
import InvestmentSection from './components/InvestmentSection'
import AdvantagesSection from './components/AdvantagesSection'
import FAQSection from './components/FAQSection'
import HowToBuySection from './components/HowToBuySection'

export default function HomePageNew() {
  return (
    <main className={`${styles.Container} homePage`}>
      <MainSection />
      <BenefitSection />
      <CryptoOilSection />
      <InvestmentSection />
      <AdvantagesSection />
      <FAQSection />
      <HowToBuySection />
    </main>
  )
}
