import React from 'react'

import styles from './index.module.scss'
import Navigation from '../Navigation'
import logo from '../../assets/images/logo.svg'

export default function Footer() {
  return (
    <footer className={`container ${styles.Footer}`}>
      <a href="/" className={styles.FooterLogoLink}>
        <img src={logo} alt="Logo" />
      </a>

      <Navigation className={styles.FooterNavigation} />

      <span className={styles.FooterYear}>© 2023</span>
    </footer>
  )
}
